import { AxiosResponse } from "axios";
import uuid from "react-uuid";

const mimicUserClick = (link: HTMLAnchorElement) => {
    document.body.appendChild(link);

    link.click();
    link.remove();
};

export const forceFileDownload = (fileName: string, file: Blob) => {
    const downloadLink = document.createElement("a");
    const fileUrl = URL.createObjectURL(file);

    downloadLink.href = fileUrl;
    downloadLink.download = decodeURIComponent(fileName);
    mimicUserClick(downloadLink);
};

export const forceFileOpen = (url: string) => {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";

    mimicUserClick(link);
};

export const extractFileNameFromContentDisHeader = (response: AxiosResponse<Blob>) => {
    const header = response.headers["content-disposition"] as string;

    // header format: attachment; filename=Specific File Name; filename*=UTF-8''Encoded Specific File Name
    const split = header?.split("; ");
    const encoded = split?.find((x) => x.startsWith("filename*="));
    const normal = split?.find((x) => x.startsWith("filename="));

    const encodedFileName = encoded?.replace("filename*=UTF-8''", "");
    const normalFileName = normal?.replace("filename=", "");

    return encodedFileName || normalFileName || `${uuid()}.pdf`;
};

export const getFileNameWithoutExtension = (file: File): string => {
    const fileName = file.name;
    const lastDotIndex = fileName.lastIndexOf(".");
    if (lastDotIndex === -1) {
        return fileName;
    }
    return fileName.substring(0, lastDotIndex);
};

export const hasInvalidCharacters = (fileName: string): boolean => {
    const regex = /^[A-Za-z0-9_]+$/;
    return !regex.test(fileName);
};

export const validateFileMimeType = (file: File): boolean => {
    const allowedMimeTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "text/csv",
        "text/plain",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/x-compressed",
        "application/x-zip-compressed",
        "application/x-rar-compressed",
        "application/x-7z-compressed",
        "application/x-tar",
        "application/x-gzip",
        "image/jpeg",
        "image/png",
    ];

    return allowedMimeTypes.includes(file.type);
};
