import * as eventsApi from "api/models/events/eventsApi";
import { services } from "api/serviceConfig";
import clsx from "clsx";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { CenteredLoadingIndicator } from "components/ReusableComponents/LoadingIndicator/CenteredLoadingIndicator";
import { LynxModal } from "components/ReusableComponents/LynxModal/LynxModal";
import { LynxIcon } from "icons/LynxIcon";
import { toastsText } from "lynxConstants";
import { observer } from "mobx-react";
import { NotificationType } from "models/shared/NotificationType";
import React, { useState } from "react";
import { useStore } from "store/StoreConfigs";
import { UploadAreaProps } from "./UploadAreaProps";
import { uploadAreaStyles } from "./UploadAreaStyles";
import { getFileNameWithoutExtension, hasInvalidCharacters, validateFileMimeType } from "helpers/fileHelpers";

export const UploadArea = observer((props: UploadAreaProps) => {
    const { thorEventViewStore, identityStore } = useStore();
    const classes = uploadAreaStyles();
    const { isEventCreatingOrEditing, onFileUploadNotification, addAttachment } = props;

    const [drag, setDrag] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const dragStartHandler = (e: React.DragEvent<HTMLDivElement>): void => {
        e.preventDefault();
        setDrag(true);
    };

    const dragLeaveHandler = (e: React.DragEvent<HTMLDivElement>): void => {
        e.preventDefault();
        setDrag(false);
    };

    const uploadToS3AndConfirm = async (file: File) => {
        if (thorEventViewStore.progressFlags.uploadingAttachments) {
            return;
        }
        thorEventViewStore.setUploadingAttachmentsFlag(true);

        try {
            const uploadFileRequest: eventsApi.UploadFileRequest = {
                file: file,
                eventId: thorEventViewStore.eventDetails.id,
                customerId: identityStore.currentCustomer.id,
            };

            const response = isEventCreatingOrEditing
                ? await services.Events.uploadFile(uploadFileRequest)
                : await services.Events.uploadFileAndSaveToDb(uploadFileRequest);

            if (response.status !== 200) {
                onFileUploadNotification(NotificationType.ERROR, toastsText.fileNotUploaded(file.name));
                return;
            }

            if (!isEventCreatingOrEditing) {
                const confirmRequest: eventsApi.ConfirmFileUploadRequest = {
                    customerId: identityStore.currentCustomer.id,
                    eventId: thorEventViewStore.eventDetails.id,
                    attachmentId: response.data.id,
                };

                await services.Events.confirmFileUpload(confirmRequest);
            }

            if (addAttachment) {
                addAttachment(response.data as any);
            }

            onFileUploadNotification(NotificationType.SUCCESS, toastsText.fileSuccessfullyUploaded(file.name));
        } catch (error) {
            onFileUploadNotification(NotificationType.ERROR, toastsText.fileNotUploaded(file.name));
        } finally {
            thorEventViewStore.setUploadingAttachmentsFlag(false);
        }
    };

    const uploadFiles = async (files: File[]) => {
        setErrorMessage("");
        for (const file of files) {
            await uploadToS3AndConfirm(file);
        }

        thorEventViewStore.incrementAttachmentsFirstSetCount();
        thorEventViewStore.setFileAttachModalOpen(false);
    };

    const validateFiles = (files: File[] | FileList) => {
        if (files.length <= 0) return true;

        for (let index = 0; index < files.length; index++) {
            // max 30MB
            const file = files[index];
            if (file.size > 31457280) {
                setErrorMessage("Max 30MB file size allowed");
                return false;
            }
            // check file name length
            const fileName = getFileNameWithoutExtension(file);
            if (fileName.length > 100) {
                setErrorMessage("Max 100 characters file name allowed");
                return false;
            }
            // check forbidden symbols
            if (hasInvalidCharacters(fileName)) {
                setErrorMessage("Filename contains forbidden characters");
                return false;
            }
            // check MimeTypes
            if (!validateFileMimeType(file)) {
                setErrorMessage("Invalid file type");
                return false;
            }
        }

        return true;
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault();
        if (e.target.files === null || thorEventViewStore.progressFlags.uploadingAttachments) {
            return;
        }

        if (!validateFiles(e.target.files)) return;

        uploadFiles(Array.from(e.target.files));
        e.target.value = "";
    };

    const onDropHandler = (e: React.DragEvent<HTMLDivElement>): void => {
        e.preventDefault();
        const files: File[] = Array.prototype.slice.call(e.dataTransfer.files);

        if (files === null || files.length === 0 || thorEventViewStore.progressFlags.uploadingAttachments) {
            return;
        }

        if (!validateFiles(files)) {
            setDrag(false);
            return;
        }

        uploadFiles(files);
        setDrag(false);
    };

    const handleModalClose = () => {
        thorEventViewStore.setFileAttachModalOpen(false);
        setErrorMessage(null);
    };

    const handleInputClick = () => {
        document.getElementById("files")!.click();
    };

    return (
        <LynxModal
            open={thorEventViewStore.isFileAttachModalOpen}
            onClose={handleModalClose}
            onConfirm={handleModalClose}
            header="Add a New Document"
            title="Choose a file from your device you’d like to attach to this event."
        >
            <div className={classes.dropArea}>
                <div
                    className={clsx(classes.dragged, drag && classes.drag)}
                    onDragStart={(e) => dragStartHandler(e)}
                    onDragLeave={(e) => dragLeaveHandler(e)}
                    onDragOver={(e) => dragStartHandler(e)}
                    onDrop={(e) => onDropHandler(e)}
                >
                    {thorEventViewStore.progressFlags.uploadingAttachments ? (
                        <CenteredLoadingIndicator />
                    ) : (
                        <div className={classes.modalContainer}>
                            <LynxIcon name="attachments" className={classes.attachmentIcon} />
                            <LynxTypography variant="body-medium">Drag and Drop here</LynxTypography>
                            <LynxTypography>or</LynxTypography>
                            <LynxButton
                                onClick={handleInputClick}
                                disabled={thorEventViewStore.progressFlags.uploadingAttachments}
                            >
                                Select a file
                            </LynxButton>
                            <input
                                id="files"
                                onChange={(e) => handleInputChange(e)}
                                name="files"
                                type="file"
                                multiple
                                className={classes.fileInputDisplayNone}
                                disabled={thorEventViewStore.progressFlags.uploadingAttachments}
                                accept=".pdf,.doc,.docx,.csv,.txt,.xls,.xlsx,.zip,.rar,.7z,.tar,.gz,.jpg,.jpeg,.png"
                            />
                        </div>
                    )}
                </div>
                {!!errorMessage && (
                    <LynxTypography color="critical500" variant="body-s" className={classes.errorMessage}>
                        {errorMessage}
                    </LynxTypography>
                )}
            </div>
        </LynxModal>
    );
});
