import { Grid } from "@material-ui/core";
import { FullNameAttachmentModel, GetDownloadUrlFromS3Request } from "api/models/events/eventsApi";
import { services } from "api/serviceConfig";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import InfoSubheader from "components/ReusableComponents/InfoSubheader";
import LynxAttachments from "components/ReusableComponents/LynxAttachments/LynxAttachments";
import { FieldArray, useFormikContext } from "formik";
import { displayPopupNotification } from "helpers/displayPopupNotification";
import { forceFileDownload } from "helpers/fileHelpers";
import { LynxIcon } from "icons/LynxIcon";
import { toastsText } from "lynxConstants";
import { observer } from "mobx-react";
import { NotificationType } from "models/shared/NotificationType";
import { ManualEventModel } from "models/thorEvents/eventModels";
import { useState } from "react";
import { useStore } from "store/StoreConfigs";
import { eventAttachmentsStyles } from "../EventAttachments/EventAttachmentsStyles";
import { UploadArea } from "../UploadArea/UploadArea";

export default observer(function AttachmentsSection() {
    const formik = useFormikContext<ManualEventModel>();
    const { thorEventViewStore, identityStore } = useStore();
    const classes = eventAttachmentsStyles();
    const [attachmentsToDisplayCount, setAttachmentsPagination] = useState(5);

    const downloadFile = async (file: FullNameAttachmentModel) => {
        if (
            thorEventViewStore.progressFlags.loadingAttachments ||
            thorEventViewStore.progressFlags.uploadingAttachments
        ) {
            return;
        }

        thorEventViewStore.setLoadingAttachmentsFlag(true);

        const getDownloadUrlRequest: GetDownloadUrlFromS3Request = {
            customerId: identityStore.currentCustomer.id,
            entityId: file.entityId,
            bucketFileName: file.bucketFileName,
        };

        const response = await services.Events.getDownloadUrlFromS3(getDownloadUrlRequest);
        const blobResponse = await services.Events.downloadFromS3WithPresignedUrl({ url: response.data });

        if (blobResponse.status !== 200) {
            thorEventViewStore.setLoadingAttachmentsFlag(false);
            displayPopupNotification(NotificationType.ERROR, toastsText.fileNotUploaded(file.fileName));

            return;
        }

        forceFileDownload(file.fileName, blobResponse.data);

        thorEventViewStore.setLoadingAttachmentsFlag(false);
    };

    const showDisplayAttachmentsCount = () => {
        const unShowedCount = formik.values.eventAttachments.length - attachmentsToDisplayCount;

        return unShowedCount > attachmentsToDisplayCount ? attachmentsToDisplayCount : unShowedCount;
    };

    const handleClick = () => {
        setAttachmentsPagination(attachmentsToDisplayCount + 5);
    };

    return (
        <>
            <Grid item className={classes.gridMarginBottomMedium}>
                <InfoSubheader
                    text={`attachments (${formik.values.eventAttachments?.length})`}
                    icon={<LynxIcon name="attachments" />}
                />
            </Grid>
            <FieldArray
                name={`eventAttachments`}
                render={(helpers) => (
                    <>
                        <UploadArea
                            onFileUploadNotification={displayPopupNotification}
                            addAttachment={(attachment: FullNameAttachmentModel) => {
                                helpers.push(attachment);
                            }}
                            isEventCreatingOrEditing={true}
                        />
                        <LynxAttachments
                            attachments={formik.values.eventAttachments.slice(0, attachmentsToDisplayCount)}
                            progressFlag={thorEventViewStore.progressFlags.loadingAttachments}
                            downloadFile={downloadFile}
                            handleRemoveAttachment={(attachment: FullNameAttachmentModel) => {
                                helpers.remove(formik.values.eventAttachments.indexOf(attachment));
                            }}
                            timezone={thorEventViewStore.manualEventModelReadOnly.timezone}
                            isRemoveDisabled={false}
                        />

                        {formik.values.eventAttachments.length > attachmentsToDisplayCount && (
                            <Grid item className={classes.gridMarginBottomMedium}>
                                <LynxButton
                                    variant="tertiary"
                                    onClick={handleClick}
                                    leftIcon={<LynxIcon name="angleSmallDown" />}
                                >
                                    Show {showDisplayAttachmentsCount()} More
                                </LynxButton>
                            </Grid>
                        )}
                        <Grid item>
                            <LynxButton
                                variant="secondary"
                                className={classes.buttonWithIcon}
                                onClick={() => thorEventViewStore.setFileAttachModalOpen(true)}
                                loading={thorEventViewStore.progressFlags.loadingAttachments}
                                leftIcon={<LynxIcon name="plusSmall" />}
                            >
                                Add Attachment
                            </LynxButton>
                        </Grid>
                    </>
                )}
            />
        </>
    );
});
